import React from 'react'
import { SkLink } from '@core/components'
import { SkIconUilPlus } from '@core/global/icons'

interface IProps {
  onClick: () => void
  className?: string
  id?: string
  children?: React.ReactNode
}

export const SkLinkAdd = (props: IProps) => {
  return (
    <SkLink onClick={props.onClick} className={props.className} id={props.id ?? 'add'}>
      <SkIconUilPlus className="pr-1" />
      {props.children ?? 'Ajouter'}
    </SkLink>
  )
}
