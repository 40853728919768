import { AbstractLabel } from '@core/models/abstractLabel'
import { action, observable } from 'mobx'
import { AbstractTransactionPrice } from '@core/models/abstractTransactionPrice'

export class AbstractAssociatedTransactionPrice<T extends AbstractTransactionPrice> extends AbstractLabel {
  @observable
  transactionPrices: T[]

  constructor(data: Partial<AbstractAssociatedTransactionPrice<T>> = null) {
    super(data)
    this.transactionPrices = data?.transactionPrices ?? []
  }

  @action
  setTransactionPrices = (prices: T[]) => {
    if (!this.transactionPrices) this.transactionPrices = []
    this.transactionPrices = prices
  }
}
