import { InputNumber } from 'antd'
import * as React from 'react'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { WithFormItem } from '@core/components/form'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'
import { ValueType } from 'rc-input-number/lib/utils/MiniDecimal'
import { useDebouncedCallback } from '@core/utils/hooks/useDebouncedCallback'
import { useEffect } from 'react'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  size?: SizeType
  step?: ValueType
  min?: number
  max?: number
  autoFocus?: boolean
  className?: string
  required?: boolean
  onBlur?: () => void
  addonBefore?: React.ReactNode
  addonAfter?: React.ReactNode
  precision?: number
} & (
    | {
        debounce?: number
        onDebounceChange: (newValue: number) => void
      }
    | {
        debounce?: never
        onDebounceChange?: (newValue: number) => never
      }
  )

export function SkNumber<T>(props: IProps<T, number>) {
  const formContext = useFormContext()
  const { id } = InitInput(props, 'SkNumber')
  const { onChangeInput, value, setFieldsValue } = ListenInput(props, id)

  const onDebounceChange = useDebouncedCallback(
    data => {
      props.onDebounceChange(data)
    },
    [],
    props.debounce ?? 1000
  )

  const onChange = data => {
    onChangeInput(data)
    if (props.onDebounceChange) onDebounceChange(data)
  }

  const formatterPrecision = (val: number) => {
    if (!val) return ''
    if (props.precision != null) {
      return parseFloat(Number(val).toFixed(props.precision)).toString().replace('.', ',')
    }
    return val.toString().replace('.', ',')
  }

  const handleFocus = event => {
    // Assurez-vous que l'élément est focus
    event.target.focus()
    // Sélectionne tout le texte à l'intérieur de l'input
    event.target.select()
  }

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  return (
    <>
      <WithFormItem {...props} id={id} value={value} rowClassName="relative">
        <InputNumber
          id={id}
          data-id={props.dataId}
          min={props.min}
          max={props.max}
          size={props.size}
          step={props.step}
          value={value}
          controls={false}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          className={props.className ?? 'w-full'}
          decimalSeparator=","
          formatter={formatterPrecision}
          onChange={onChange}
          onBlur={props.onBlur}
          disabled={props.disabled || formContext.props.disabled}
          onFocus={handleFocus}
          addonBefore={props.addonBefore}
          addonAfter={props.addonAfter && typeof props.addonAfter === 'string' ? <span className="text-xs text-gray-400">{props.addonAfter}</span> : props.addonAfter}
        />
      </WithFormItem>
    </>
  )
}
