import { ApiStore } from '@core/api/apiStore'
import { AbstractAssociatedTransactionPrice } from '@core/models/abstractAssociatedTransactionPrice'
import { AbstractTransactionPrice } from '@core/models/abstractTransactionPrice'
import { action } from 'mobx'
import { Http } from '@core/api/http'
import { Message } from '@core/service/message'

export abstract class ApiAssociatedTransactionPriceStore<T extends AbstractAssociatedTransactionPrice<Ttype>, Ttype extends AbstractTransactionPrice> extends ApiStore<T> {
  protected constructor(
    protected baseUrlHost: string,
    public baseUrlResource: string,
    public tCreator: { new (anys?: any): T },
    public objectNameGenre: 'male' | 'female',
    public objectName: string,
    public itemName: string,
    public thisItemName: string,
    public icon: any,
    public route: string = '',
    public typeEdit: 'route' | 'modal' = 'modal'
  ) {
    super(baseUrlHost, baseUrlResource, tCreator, objectNameGenre, objectName, itemName, thisItemName, icon, route, typeEdit)
  }

  @action
  async $addTransactionPrice(data: Ttype, item: T) {
    const value = await Http.$post<T>(`${this.baseUrl}/${item.id}/transaction-price`, data)
    Message.success('Nouveau prix ajouté')
    return value
  }

  @action
  async $deleteTransactionPrice(itemId: number, priceId: number) {
    const value = await Http.$delete<T>(`${this.baseUrl}/${itemId}/transaction-price/${priceId}`)
    Message.success('Le prix a été supprimé')
    return value
  }
}
