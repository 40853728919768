import { makeObservable, observable } from 'mobx'
import { Price } from 'skazy-gescom-common/models/price/price'
import { AbstractUi } from '@core/models/abstractItem'

export class AbstractTransactionPrice {
  @observable
  id: number
  @observable
  startDate: Date
  @observable
  endDate: Date
  @observable
  price: Price
  @observable
  ui?: AbstractUi

  constructor(data?: Partial<AbstractTransactionPrice>) {
    makeObservable(this)
    Object.assign(this, data)
    this.ui = new AbstractUi()
    if (this.price) this.price = new Price(this.price)
  }
}
